'use client'

import { ReactNode, useCallback, useState, useMemo, useEffect, forwardRef, useRef, CSSProperties } from 'react'
import { calculateAspectRatio, cls, isMobile, whisper } from '@/utils'
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area'
import { Creation, CreationBase, GalleryItemSource, PoNVoid, Size } from '@/types'
import Loading from '@/components/loading'
import Empty from '../empty'
import Button from '../button'
import GalleryItem from './video-card'
import GallaryImageItem from './image-card'
import { useCachedMyProfile } from '@/hooks/useMyProfile'
import { useBreakpoint } from '@/hooks/useBreakPoint'
import { IntersectionGuard } from '@haiper/react-components'
import { useWindowSize } from 'usehooks-ts'
import Skeleton from '@/components/skeleton'
import useBrowser from '@/hooks/useBrowser'
import Spotlights from '@/components/spotlight/carousel'
import useGridColumns from '@/hooks/useGridColumns'
import MagicWaterfall, { WaterfallProps } from '../waterfall/magic'

export interface GalleryItem {
  id: string
  type: string
  thumbnailUrl?: string
  videoUrl?: string
}

export interface GalleryFilter {
  label: string
  value: string
}

export interface GalleryProps {
  className?: string
  items?: Creation[]
  source: GalleryItemSource
  loading?: boolean
  emptyDescription?: string | ReactNode
  emptyClassName?: string
  onClick?: (item: CreationBase) => PoNVoid
  filters?: GalleryFilter[]
  onFilter?: (filter: GalleryFilter) => PoNVoid
  defaultFilter?: GalleryFilter
  loadMore?: (page?: number) => PoNVoid
  hasMore?: boolean
  singleColumn?: boolean
  showSpotlights?: boolean
  waterfall?: boolean
}

const cacheMargin = 12
const defaultAspectRatio = 16 / 9

const Gallery = forwardRef<HTMLDivElement, GalleryProps>(
  (
    {
      singleColumn,
      className,
      items,
      loading,
      filters,
      loadMore,
      hasMore,
      onFilter,
      source,
      defaultFilter,
      showSpotlights,
      emptyDescription,
      emptyClassName,
      waterfall,
      onClick,
    }: GalleryProps,
    ref,
  ) => {
    const [activeFilter, setActiveFilter] = useState<GalleryFilter | null>(defaultFilter ?? filters?.[0] ?? null)
    const innerRef = useRef<HTMLDivElement | null>(null)

    const { isBelowMd } = useBreakpoint('md')
    const browser = useBrowser()

    const gridClassname = useMemo(
      () =>
        cls(
          'w-full grid grid-cols-1 xs:grid-cols-1 gap-8 gap-3 md:gap-6',
          singleColumn ? 'md:gap-8' : 'tablet:grid-cols-2 desktop:grid-cols-3',
          waterfall && 'widescreen:grid-cols-4',
          className,
        ),
      [singleColumn, className, waterfall],
    )

    const columnCount = useGridColumns(gridClassname)

    useEffect(() => {
      if (defaultFilter) {
        setActiveFilter((old) => old ?? defaultFilter)
      }
    }, [defaultFilter])

    const { data: me, isValidating: profileLoading } = useCachedMyProfile()

    const handleFilterClick = useCallback(
      (newFilter: GalleryFilter) => {
        setActiveFilter(newFilter)
        onFilter?.(newFilter)
      },
      [onFilter],
    )

    const [hovingCreationId, setHovingCreationId] = useState<string | null>(null)

    const [deletedIds, setDeletedIds] = useState<string[]>([])
    const visibleItems = useMemo(() => {
      return items?.filter((item) => !deletedIds.includes(item.creation_id))
    }, [items, deletedIds])

    const handleDelete = useCallback((data: CreationBase) => {
      setDeletedIds((prev) => [...prev, data.creation_id])
    }, [])

    const checkLoadMore = useCallback(
      (creation: CreationBase) => {
        const index = items?.findIndex((item: CreationBase) => item.creation_id === creation.creation_id) ?? -1
        if (index > (items?.length ?? 0) - cacheMargin && hasMore !== false) {
          loadMore?.()
        }
      },
      [loadMore, items, hasMore],
    )

    const { width: windowWidth, height: windowHeight } = useWindowSize()

    const gapSize = useMemo(() => {
      return isBelowMd || singleColumn ? 8 * 4 : 6 * 4
    }, [singleColumn, isBelowMd])

    const calculateItemWidth = useCallback(() => {
      const gridWidth = innerRef.current?.clientWidth ?? 0
      const maxWidth = 720 // 720 is the max width of single column mode, corresponding to md:w-[720px] in the app/creations/page.tsx file
      const result = Math.max(0, Math.floor((gridWidth - (columnCount - 1) * gapSize) / columnCount))
      if (singleColumn && result > maxWidth) {
        return maxWidth
      }
      return result
    }, [columnCount, gapSize, singleColumn])

    const [itemWidth, setItemWidth] = useState(calculateItemWidth())

    const hideBorderAndPadding = waterfall

    const calculateHeight = useCallback(
      (aspectRatio: number) => {
        const paddingSize = hideBorderAndPadding ? 0 : 8 * 2
        const xBorderSize = hideBorderAndPadding ? 0 : 2 + 2
        const yBorderSize = hideBorderAndPadding ? 0 : 2 + 4
        const videoWidth = itemWidth - paddingSize - xBorderSize // 2 * 8 is the padding
        const videoHeight = videoWidth / aspectRatio
        const footerAndGapSize = 36 + 8
        const headerSize = source === 'creations' || source === 'collection' ? 32 + 8 : 0

        const height = videoHeight + headerSize + footerAndGapSize + paddingSize + yBorderSize
        return height
      },
      [itemWidth, source, hideBorderAndPadding],
    )

    const updateItemSize = useCallback(() => {
      setItemWidth(calculateItemWidth())
    }, [calculateItemWidth])

    useEffect(() => {
      requestAnimationFrame(updateItemSize)
    }, [singleColumn, windowWidth, windowHeight, updateItemSize])

    const handleItemShow = useCallback(
      (creation: CreationBase) => {
        checkLoadMore(creation)
        requestAnimationFrame(updateItemSize)
      },
      [checkLoadMore, updateItemSize],
    )

    const spotlightSize: Size = useMemo(() => {
      const spotlightWidth = isBelowMd ? itemWidth : itemWidth * 2 + gapSize
      return {
        width: spotlightWidth,
        height: (spotlightWidth / 16) * 9 + 168,
        // height: 168 + spotlightWidth / 16 * 9,
      }
    }, [gapSize, itemWidth, isBelowMd])

    const containerProps: Partial<WaterfallProps> = useMemo(() => {
      if (waterfall && showSpotlights) {
        const waterfallPaddingTop = Math.max(spotlightSize.height, 0)
        const res: Partial<WaterfallProps> = {
          initialPaddings: [waterfallPaddingTop, waterfallPaddingTop],
          style: {
            transform: `translateY(-${spotlightSize.height}px)`,
          },
        }
        return res
      }
      return {}
    }, [waterfall, spotlightSize, showSpotlights])

    const spotlightStyle: CSSProperties = useMemo(() => {
      return {
        width: `${spotlightSize.width}px`,
        height: `${spotlightSize.height}px`,
      }
    }, [spotlightSize])

    const renderContent = () => {
      if (profileLoading || (loading && !items?.length)) {
        return (
          <div
            className={cls('flex size-full justify-center items-center fixed inset-0 pointer-events-none', className)}
          >
            <Loading />
          </div>
        )
      }

      if (visibleItems?.length === 0) {
        return (
          <div className={cls('flex pt-[150px] md:pt-0 size-full justify-center items-center', className)}>
            <Empty description={emptyDescription} className={emptyClassName} />
          </div>
        )
      }

      const getVariant = () => {
        if (isMobile()) {
          // return 'transparent'
          return 'outline'
        }
        if (singleColumn) {
          return 'outline'
        }
        return 'transparent'
      }

      // const Container = waterfall ? Waterfall : 'div'
      const Container = waterfall ? MagicWaterfall : 'div'

      return (
        <div ref={innerRef} className='flex flex-col w-full'>
          {showSpotlights && waterfall && (
            <div style={spotlightStyle}>
              <Spotlights
                hideToolbar
                className={cls(
                  'tablet:col-span-2 tablet:row-span-2 p-0 z-0 overflow-hidden',
                  hideBorderAndPadding ? 'border-none border-0 p-0' : '',
                  !itemWidth && 'invisible',
                )}
                carouselItemClassName='basis-full'
                style={spotlightStyle}
              />
            </div>
          )}
          <Container
            {...containerProps}
            className={cls(gridClassname, 'pointer-events-none')}
            data-testid='creations-gallery-content'
          >
            {showSpotlights && !waterfall && (
              <Spotlights
                hideToolbar
                className={cls(
                  'tablet:col-span-2 tablet:row-span-2 p-0 z-0 overflow-hidden pointer-events-auto',
                  !!itemWidth && 'invisible',
                )}
                carouselItemClassName='basis-full'
                style={spotlightStyle}
              />
            )}
            {(visibleItems ?? [])?.map((creation, index) => {
              const currentItemAspectRatio = calculateAspectRatio(creation?.spec?.width, creation?.spec?.height)
              const calculatedHeight = calculateHeight(waterfall ? currentItemAspectRatio : defaultAspectRatio)

              return (
                <IntersectionGuard
                  key={`guard-${creation.creation_id}`}
                  width={itemWidth || undefined}
                  height={calculatedHeight || undefined}
                  containerProps={{
                    className: cls(
                      'relative',
                      browser?.name !== 'safari' ? (hovingCreationId === creation.creation_id ? 'z-[1]' : 'z-0') : '',
                      !itemWidth && 'hidden',
                    ),
                    onMouseEnter: () => setHovingCreationId(creation.creation_id),
                    onMouseLeave: () => setHovingCreationId((old) => (old === creation.creation_id ? null : old)),
                  }}
                  fallback={
                    <div
                      className={cls(
                        'size-full rounded-lg border-2 border-b-4 border-solid border-border hover:border-border-hover active:border-border-hover p-2',
                        !itemWidth && 'hidden',
                        waterfall && 'border-none border-0 p-0',
                      )}
                    >
                      <Skeleton
                        variant={source === 'creations' || source === 'collection' ? 'creation' : 'video'}
                        className='size-full'
                      />
                    </div>
                  }
                >
                  {() =>
                    creation.output_type === 'image' ? (
                      <GallaryImageItem
                        key={creation.creation_id + index}
                        waterfall={waterfall}
                        source={source}
                        creation={creation}
                        userId={me?.user_id ?? null}
                        className={cls('', singleColumn || hideBorderAndPadding ? 'border-none border-0' : '')}
                        size={singleColumn && !isBelowMd ? 'md' : 'sm'}
                        singleColumn={singleColumn}
                        variant={getVariant()}
                        onClick={onClick}
                        onShow={checkLoadMore}
                        onDelete={handleDelete}
                      />
                    ) : (
                      <GalleryItem
                        key={creation.creation_id}
                        waterfall={waterfall}
                        source={source}
                        creation={creation}
                        userId={me?.user_id ?? null}
                        singleColumn={singleColumn}
                        className={cls('', singleColumn || hideBorderAndPadding ? 'border-none border-0 p-0' : '')}
                        variant={getVariant()}
                        size={singleColumn && !isBelowMd ? 'md' : 'sm'}
                        onClick={onClick}
                        onShow={handleItemShow}
                        onDelete={handleDelete}
                      />
                    )
                  }
                </IntersectionGuard>
              )
            })}
          </Container>
        </div>
      )
    }

    return (
      <div
        // ref={ref}
        ref={(node) => {
          innerRef.current = node
          if (typeof ref === 'function') {
            ref(node)
          } else if (ref) {
            ref.current = node
          }
        }}
        className={cls('flex flex-1 size-full flex-col h-max')}
        data-testid='creations-gallery'
      >
        {filters && filters?.length > 0 ? (
          <ScrollArea className='whitespace-nowrap rounded-md md:rounded-[16px]'>
            <div className='flex mb-4 gap-4'>
              {filters.map((filter, index) => {
                const active = activeFilter === filter
                return (
                  <Button
                    key={[filter, index].join()}
                    className={cls(
                      'rounded-[20px] px-4 py-1 bg-surface hover:bg-surface-hover active:bg-surface-hover border-2 border-b-4 text-body-lg',
                      active ? 'text-text-interactive border-border-active hover:bg-surface' : '',
                    )}
                    onClick={() => handleFilterClick(filter)}
                  >
                    {filter.label}
                  </Button>
                )
              })}
            </div>
            <ScrollBar orientation='horizontal' />
          </ScrollArea>
        ) : null}
        {renderContent()}
      </div>
    )
  },
)

Gallery.displayName = 'Gallery'
export default Gallery
