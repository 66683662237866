import { useCallback, useEffect, useMemo, useState } from 'react'
import { Creation, CreationBase, GalleryItemSource, PoNVoid } from '@/types'
import { cls, isMobile, openNewTab, preventDefault, stopPropagation, utcDate, utcDateTime, whisper } from '@/utils'
import { Prompt } from '@/app/creations/_prompt'
import Interactions, { InteractionsProps, defaultControls } from '@/components/interactions'
import { useSetAtom } from 'jotai'
import { creationCacheAtom } from '@/atoms'
import ErrorJob from '@/components/job/error'
import MainCard from './main'
import PendingJob from '@/components/job/pending'
import useJobStatus from '@/hooks/useJobStatus'
import useCreation from '@/hooks/useCreation'
import { useInView } from 'react-intersection-observer'
import useAmplitude from '@/hooks/useAmplitude'
import { retry } from '@/service/job.service'
import { nanoid } from 'nanoid'
import { DEFAULT_AVATAR } from '@/constants'
import Avatar from '@/components/avatar'
import useCreationActions from '@/hooks/useCreationActions'

interface ImageCardProps {
  className?: string
  source: GalleryItemSource
  creation: Creation
  singleColumn?: boolean
  userId: string | null
  onClick?: (item: CreationBase) => PoNVoid
  onDelete?: (item: CreationBase) => PoNVoid
  onShow?: (item: CreationBase) => PoNVoid
  size?: 'sm' | 'md'
  variant?: 'outline' | 'transparent'
  waterfall?: boolean
}

export default function ImageCard({
  className,
  creation,
  source,
  userId,
  size = 'sm',
  singleColumn,
  variant,
  waterfall,
  onClick,
  onDelete,
  onShow,
}: ImageCardProps) {
  const isInCreations = source === 'creations'
  const isInCollections = source === 'collection'
  const [retrying, setRetrying] = useState(false)
  const setCreationCache = useSetAtom(creationCacheAtom)
  const [jobStatusRefreshKey, setJobStatusRefreshKey] = useState('')
  const { track } = useAmplitude()
  const creationId = source === 'profile' ? creation?.output_id || '' : creation?.creation_id
  const shouldQueryJobStatus = isInCreations && creation?.status !== 'succeed'

  const { data: innerJobStatus } = useJobStatus(shouldQueryJobStatus ? creationId : undefined, jobStatusRefreshKey)
  const { data: innerCreation } = useCreation(innerJobStatus?.status === 'succeed' ? creationId : undefined)
  const jobStatus = shouldQueryJobStatus ? innerJobStatus ?? null : null
  const hasOutput = !isInCreations || creation?.status === 'succeed' || jobStatus?.status === 'succeed'
  const showAuthorInfo = !isInCreations

  const latestData: Creation = innerCreation?.data || creation

  const actions = useCreationActions({
    source,
    creationId,
    outputId: latestData?.output_id,
    initialData: latestData,
    url: latestData?.video_url || latestData?.output_url || '',
    onDelete: () => onDelete?.(latestData),
  })

  const hasError =
    !retrying && (jobStatus?.status === 'failed' || (!shouldQueryJobStatus && latestData?.status === 'failed'))
  const isOwner = creation?.user_id && creation?.user_id === userId
  const showMode = useMemo(() => {
    if (isMobile()) return 'mobile'
    if (!singleColumn) return 'grid'
    return 'single'
  }, [singleColumn])

  const trackEventParams = useMemo(() => {
    return {
      creation_id: creation?.creation_id,
      source,
      is_author: isInCreations,
      create_time: creation?.create_time ? utcDateTime(creation?.create_time) : null,
      // UTC + 0
      create_date: creation?.create_time ? utcDate(creation?.create_time) : null,
    }
  }, [source, creation, isInCreations])

  useEffect(() => {
    const data = innerCreation?.data
    if (data) {
      setCreationCache((prev) => {
        return {
          ...prev,
          [data.creation_id]: data,
        }
      })
    }
  }, [innerCreation, setCreationCache])

  useEffect(() => {
    if (innerJobStatus?.status === 'succeed') {
      track('click:creation:generate-success', {
        creation_id: creationId,
      })
    }
  }, [innerJobStatus, track, creationId])

  const interactionControls: InteractionsProps['controls'] = useMemo(() => {
    if (isInCreations) {
      return {
        ...defaultControls,
        showRegenerate: true,
        showVaryPrompt: true,
        showDownload: false,
        showFavorite: false,
        showDelete: true,
        showVisibility: true,
        showCollection: true,
        showShare: 'fold',
      }
    }
    if (isInCollections) {
      return {
        showVisibility: 'fold',
        showDelete: !!isOwner,
        showShare: isOwner ? 'fold' : true,
        showCollection: !!isOwner,
        showFavorite: !isOwner,
      }
    }
    return {
      showShare: false,
      showFavorite: true,
      showDownload: false,
      showFavoriteCount: true,
      showComment: true,
    }
  }, [isInCreations, isOwner, isInCollections])

  const { ref: contentRef, inView } = useInView({
    threshold: 0.75,
    triggerOnce: true,
    onChange(inView, entry) {
      if (inView) {
        onShow?.(creation)
      }
    },
  })

  const handleRetry = useCallback(
    async (e: any) => {
      e?.preventDefault?.()
      e?.stopPropagation?.()

      if (creationId) {
        await retry(creationId)
        setRetrying(true)
        setTimeout(() => {
          setJobStatusRefreshKey(nanoid())
          setRetrying(false)
        }, 10 * 1000)
      }
    },
    [creationId],
  )

  const renderMain = () => {
    if (hasOutput) {
      return (
        <MainCard waterfall={waterfall} creation={latestData} source={source} onClick={() => onClick?.(latestData)} />
      )
    }
    if (hasError) {
      return <ErrorJob showMode={showMode} handleRetry={handleRetry} jobStatus={jobStatus} creation={latestData} />
    }
    return <PendingJob showMode={showMode} creation={latestData} jobStatus={jobStatus} />
  }

  const handleClick = useCallback(
    (e: any) => {
      if (e.target === e.currentTarget && ['profile'].includes(source)) {
        onClick?.(latestData)
      }
    },
    [latestData, source, onClick],
  )

  const handleGotoAuthorProfile = useCallback(() => {
    if (!creation?.user_id) return

    track('click:creation:author', {
      ...trackEventParams,
      target_user_id: creation?.user_id,
    })

    const url = `/profile/${creation?.user_id}`
    openNewTab(url)
  }, [creation, track, trackEventParams])

  const handleFooterClick = useCallback(
    async (e: any) => {
      stopPropagation(e)
      preventDefault(e)
      if (isInCreations || isInCollections) {
        return
      }
      await handleClick?.(e)
    },
    [isInCreations, isInCollections, handleClick],
  )

  return (
    <div
      ref={contentRef}
      key={latestData.creation_id}
      className={cls(
        'flex flex-col justify-center items-center h-auto rounded-lg border-2 border-b-4 border-solid border-border p-2 box-content pointer-events-auto',
        isInCollections || isInCreations ? 'cursor-default' : 'hover:border-border-hover',
        waterfall ? 'p-0 h-full' : 'tablet:aspect-video',
        size === 'sm' ? 'gap-2' : 'gap-3',
        className,
      )}
      aria-label='gallery-image-item'
      onClick={handleClick}
    >
      {(isInCreations || isInCollections) && (
        <Prompt
          {...latestData}
          size={size}
          className='w-full'
          variant={variant}
          iconClassName='bg-band-100 text-band-500'
        />
      )}
      {renderMain()}
      <div
        className={cls(
          'w-full flex justify-between text-icon items-center h-9 @container',
          isInCreations || isInCollections ? 'cursor-default' : 'cursor-pointer',
        )}
        aria-label='image-footer'
        onClick={handleFooterClick}
      >
        <div
          className={cls(
            '@sm:mr-1 @md:mr-5 cursor-pointer flex gap-2.5 items-center p-0.5 pr-1 hover:bg-surface-hover rounded-md w-0 flex-1 max-w-max',
            showAuthorInfo ? '' : 'hidden',
          )}
          aria-label='author info'
        >
          <Avatar src={creation?.avatar || DEFAULT_AVATAR} />
          <div
            className='text-body-sm whitespace-nowrap truncate'
            aria-label='author name'
            onClick={handleGotoAuthorProfile}
          >
            {creation?.username ?? ''}
          </div>
        </div>
        <Interactions
          size={size}
          useButtonGroup={isInCreations}
          actions={actions}
          source={source}
          singleColumn={singleColumn}
          disabled={latestData?.is_illegal || !hasOutput}
          className={cls('min-w-max overflow-hidden w-auto md:h-10', showAuthorInfo ? '' : 'flex-1')}
          outputType={latestData?.output_type}
          controls={interactionControls}
          itemClassName={cls(
            'text-icon disabled:bg-transparent bg-transparent hover:bg-surface-hover active:bg-surface-hover w-auto px-0 h-10 px-[6px]',
          )}
          iconClassName={cls('bg-transparent hover:bg-transparent active:bg-transparent')}
          onClick={handleClick}
        />
      </div>
    </div>
  )
}
