'use client'

import ChevronRight from '@haiper/icons-svg/icons/outline/chevron-right.svg'
import { useCachedSpotlights } from '@/hooks/useSpotlights'
import Link from '@/components/link'
import SpotlightCard, { SpotlightCardRef } from './card'
import Carousel, { CarouselApi } from '@/components/carousel'
import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { cls } from '@/utils'
import { Spotlight } from '@/types'
import { useRouter } from 'next/navigation'

export interface SpotlightsProps {
  className?: string
  hideToolbar?: boolean
  carouselItemClassName?: string
  style?: CSSProperties
}

export default function Spotlights({ className, hideToolbar, carouselItemClassName, style }: SpotlightsProps) {
  const { data: spotlights, isValidating: spotlightsLoading } = useCachedSpotlights()

  const [api, setApi] = useState<CarouselApi>()

  const itemRefs = useRef<Record<string, SpotlightCardRef | null>>({})

  const pauseAll = useCallback(() => {
    for (const spotlightId in itemRefs.current) {
      try {
        itemRefs.current[spotlightId]?.videoRef.current?.pause()
      } catch (error) {
        // do nothing
      }
    }
  }, [])

  const thumbWidth = Math.ceil(200 / (spotlights?.length || 1))

  useEffect(() => {
    if (api) {
      if (!api) {
        return
      }

      api.on('select', () => {
        pauseAll()
      })
    }
  }, [api, thumbWidth, pauseAll])

  const setRef = (spotlight: any) => {
    return (item: any) => {
      itemRefs.current[spotlight.spotlight_id] = item
    }
  }

  const router = useRouter()
  const handleSpotlightClick = useCallback(
    (spotlight: Spotlight) => {
      router.push(`/spotlight/${spotlight.spotlight_id}`)
    },
    [router],
  )

  if (spotlightsLoading) {
    return null
  }

  return (
    <div
      className={cls(
        'pt-8 flex flex-col gap-4 w-full items-center border-2 border-b-4 border-border rounded-lg p-2 h-full',
        className,
      )}
      aria-label='spotlights'
      data-testid='spotlights'
      style={style}
    >
      <Carousel
        pauseOnHover
        setApi={setApi}
        className='w-full relative'
        dataSource={spotlights}
        itemClassName={cls('basis-2/3', carouselItemClassName)}
        footerVariant='dot'
        previousRenderer={() => null}
        nextRenderer={() => null}
        itemRenderer={(spotlight, index) => {
          return (
            <SpotlightCard
              ref={setRef(spotlight)}
              key={spotlight.spotlight_id}
              playOnHover
              data={spotlight}
              className='border-0 border-none p-0 md:p-0 h-full'
              onClick={() => {
                handleSpotlightClick(spotlight)
              }}
              onVideoClick={() => {
                handleSpotlightClick(spotlight)
              }}
            />
          )
        }}
      />
    </div>
  )
}
